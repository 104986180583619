.team-member-image {
    filter: grayscale(100%);
    border-radius: 50%;
    width: 300px; 
    height: 300px; 
    object-fit: cover; 
    transition: filter 0.7s ease; 
    margin-bottom: 10px;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-black);
    opacity: 80%; /* Blue with transparency */
    z-index: 1; /* Above the video but below other content */
  }

.team-member-container:hover .team-member-image {
/* Removes the grayscale filter on hover, making the image colored */
    filter: none;
}

.team-member-container {
    text-align: center;
    margin: 5rem;
}

.team-member-title {
    margin-bottom: 5px;
}

.team-member-social-links-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.team-member-social-link {
    margin: 5px 10px;
}