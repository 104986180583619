.mobile-lang-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 150;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(256.66deg, #131c3c 5.62%, #253674 97.31%);
  padding: 8.5%;
  overflow-y: scroll;
}

.close-lang-container {
  width: 100%;
  display: flex;
  cursor: pointer;
}

.mobile-lang-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 85px;
  color: var(--color-grey-3);
}

.lang-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(113, 119, 138, 0.4);
  cursor: pointer;
}

.lang-item {
  font-size: 20px;
  line-height: 30px;
}

.selected-lang-icon {
  width: 30px;
  height: 30px;
}
