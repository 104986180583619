.email-container {
    display: flex;
    align-items: center;
}

.email-container .copy-svg {
    margin-left: 5px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}
  
.email-container .copy-svg:hover {
transform: scale(1.1);
}