.our-pro-services {
  padding: 89px 0 71px;
  background-color: #fafbfe;
}

.our-pro-services-subtitle {
  margin-bottom: 4px;
  color: var(--color-primary);
}

.our-pro-services-title {
  margin-bottom: 80px;
}

.services-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.service-container {
  width: 100%;
  min-height: 340px;
  display: flex;
  flex-direction: column;
}

.service-container .icon-right-btn{
  margin-top: auto;
}

.service-container p {
  margin: 24px 0 22px;
}

.sercvice-icon {
  margin-bottom: 22px;
}

@media (max-width: 980px) {
  .our-pro-services {
    padding: 96px 0;
  }

  .our-pro-services-title {
    margin-bottom: 36px;
  }

  .services-wrapper {
    flex-direction: column;
  }

  .service-container {
    min-height: 275px;
    margin-bottom: 36px;
  }
  .service-container > .icon-right-btn {
    padding-left: 3px;
  }

  .sercvice-icon {
    margin-bottom: 12px;
  }

  .service-container p {
    margin: 12px 0;
  }
}
