.person-image {
  border-radius: 50%;
}

.card-container {
  width: 100%;
  padding: 0 10px;
  max-width: 359px;
  min-height: 367px;
  display: flex;
  flex-direction: column;
}

.testimonial-text {
  margin-top: 18px;
  margin-bottom: 28px;
}

.person-container {
  margin-top: auto;
  display: flex;
  align-items: center;
  padding-top: 18px;
  border-top: 1px solid rgba(167, 173, 195, 0.25);
}

.person-details-container {
  margin-left: 20px;
}

.person-details-container > p {
  font-size: 14px;
  line-height: 21px;
}

.testimonial-date {
  color: var(--color-grey-1);
}

@media (max-width: 410px) {
  .card-container {
    padding: 0 5px;
    margin-left: -10px;
  }
}

@media (max-width: 930px) {
  .card-container {
    margin: 0 auto;
  }
}
