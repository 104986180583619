.submit-step-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.submit-step-container {
  width: 100%;
  max-width: 794px;
  display: flex;
  flex-direction: column;
}

.submit-step-container > h3 {
  margin-bottom: 27px;
}

.source-container > .content-text-field {
  background: var(--color-white);
}

.source-container > .content-text-field > .text-input {
  resize: none;
}

.source-container > .content-text-field > .text-input::-webkit-scrollbar-thumb {
  border-color: #ffffff;
}

.source-container > .content-text-field > .fade {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.10208816705336432) 12%, rgba(255, 255, 255, 1) 56%);
}

.submit-step-flex {
  display: flex;
  justify-content: space-between;
}

.notes-container {
  margin-bottom: 40px;
}

.notes-container > .content-text-field > .text-input {
  min-height: 150px;
}

.counted-container {
  margin-bottom: 24px;
}

.checkmark-container {
  margin-right: 152px;
}

.btn-accept-container {
  display: flex;
}

.source-files {
  position: relative;
  width: 100%;
  max-width: 454px;
  padding-bottom: 33px;
  border: 1px solid rgba(167, 173, 195, 0.3);
  border-radius: 8px;
  color: var(--color-grey-2);
}

.source-files > .fade {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, #ffffff 63.21%);
}

.submit-step-wrapper > .navigation-button-container {
  margin-top: 80px;
}

.btn-accept-container > .main-btn {
  width: 218px;
  padding: 10px 21px;
}

.btn-accept-container > .secondary-btn {
  width: 218px;
}

.payment-details-wrapper {
  width: 100%;
  max-width: 796px;
  padding: 60px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(217, 223, 244, 0.24);
  border-radius: 8px;
}

.payment-details-container {
  width: 100%;
  max-width: 340px;
  display: flex;
  flex-direction: column;
}

.expiry-cvc,
.country-code {
  max-height: 41px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.expiry-cvc > .custom-input > input,
.country-code > .translate-dropdown > .translate-dropdown-selected,
.country-code > .custom-input > input {
  width: 100%;
  max-width: 160px;
}

.country-code > .translate-dropdown {
  margin: 0;
  max-width: 160px;
}

.payment-details-container > .custom-input > input {
  width: 100%;
  max-width: 340px;
}

.payment-details-container > .custom-input > label,
.expiry-cvc > .custom-input > label,
.country-code > .custom-input > label {
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 51%, rgba(217, 223, 244, 0) 100%);
}

.payment-details-container > .custom-input {
  width: 100%;
  margin-bottom: 20px;
}

.payment-details-container > .main-btn {
  width: 100%;
  max-width: 218px;
  margin: 20px 0 0 0;
  align-self: center;
}

@media (max-width: 940px) {
  .submit-step-flex {
    flex-wrap: wrap;
    justify-content: center;
  }

  .submit-step-container > h3 {
    text-align: center;
  }

  .submit-step-flex > h6 {
    margin-bottom: 24px;
  }
}

@media (max-width: 710px) {
  .submit-step-wrapper > .navigation-button-container {
    flex-direction: column;
  }

  .btn-accept-container > .main-btn,
  .btn-accept-container > .secondary-btn {
    width: 100%;
    max-width: 366px;
    align-self: center;
    margin: 0 0 20px 0;
  }

  .btn-accept-container {
    flex-direction: column;
    margin-top: 35px;
  }

  .checkmark-container {
    margin-right: 0;
  }
}

@media (max-width: 980px) {
  .submit-step-flex {
    justify-content: flex-start;
  }
}

@media (max-width: 410px) {
  .expiry-cvc > .custom-input > input,
  .country-code > .translate-dropdown > .translate-dropdown-selected,
  .country-code > .custom-input > input {
    width: 90%;
  }
}
