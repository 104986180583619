.team-member-page-container {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    margin: 0;
    align-items: center;
    cursor: default;
    text-align: center;
}

.team-member-header {
    color: var(--color-white);
    height: auto;
    padding: 3% 15%;
    margin-bottom: 5%;
    margin-top: 2.5%;
}

.team-member-main {
    color: var(--color-white);
    background-color: var(--color-white);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 4%;
}

.team-member-image-overlap {
    width: 300px; 
    height: 300px; 
    border-radius: 50%;
    object-fit: cover;
    position: relative;
    top: -100px;
    z-index: 1;
}

.team-member-description {
    color: var(--color-black);
    width: 80%; /* Adjusted for better readability on mobile */
    font-size: large;
    text-align: center;
    padding: 0 20px; /* Add padding for text not to touch the edges */
    padding-bottom: 5%;
}

.team-member-blogs {
    background-color: var(--color-white);
    width: 100%;
}

.author-latest-blog-posts {
    background-color: transparent;
    padding-top: 4%;
    padding-bottom: 4%;
}

/* Media Queries for devices with width less than 768px */
@media screen and (max-width: 768px) {
    .team-member-image-overlap {
        width: 200px; /* Smaller image for mobile */
        height: 200px;
        top: -30px; /* Less overlap for smaller screens */
    }

    .team-member-description {
        width: 90%; /* Use more width for text on small screens */
        font-size: medium; /* Adjust font size for readability */
    }
}
