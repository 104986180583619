.our-services-hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.our-services-image {
  max-width: 71%;
  margin-left: -160px;
}

.our-services-text-container {
  max-width: 455px;
  margin-left: 115px;
  white-space: pre-line;
}

.our-services-subtitle {
  color: var(--color-primary);
}

.our-services-title {
  margin-top: 4px;
  margin-bottom: 24px;
}

.our-services-bg-icon {
  position: absolute;
  right: 0;
  bottom: 34%;
  max-width: 20%;
}

.our-services-question-container {
  display: flex;
  margin-top: 128px;
}

.our-services-question-container > h2 {
  max-width: 44%;
  margin-right: 8%;
}

.our-services-checkmark-container {
  max-width: 50%;
}

.our-services-answer-container {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.our-services-answer-container > svg {
  flex: none;
  margin-right: 24px;
}

.our-services-answer-container > p {
  max-width: 430px;
}

@media (max-width: 970px) {
  .our-services-text-container {
    margin-left: 75px;
  }

  .our-services-title {
    font-size: 28px;
    line-height: 39px;
  }

  .our-services-bg-icon {
    bottom: 45%;
  }

  .our-services-question-container {
    margin-top: 96px;
  }
}

@media (max-width: 980px) {
  .our-services-hero {
    flex-wrap: wrap;
    justify-content: center;
  }

  .our-services-image {
    max-width: 91%;
    margin-left: 0;
  }

  .our-services-text-container {
    margin-left: 0;
    margin-top: 36px;
  }

  .our-services-question-container {
    flex-wrap: wrap;
    justify-content: center;
  }

  .our-services-question-container > h2 {
    max-width: 277px;
    margin-bottom: 36px;
    font-size: 28px;
    line-height: 39px;
  }

  .our-services-checkmark-container {
    max-width: 366px;
  }
}

@media (max-width: 980px) {
  .our-services-image {
    max-width: 100%;
  }

  .our-services-bg-icon {
    bottom: 46%;
    max-width: 30%;
  }

  .our-services-question-container {
    flex-wrap: wrap;
    justify-content: left;
  }
}
