.hero-wrapper {
  position: relative;
  width: 100%;
  margin-top: 207px;
  padding-bottom: 75px;
}

.hero-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hero-text-container {
  display: flex;
  flex-direction: column;
  max-width: 40.5%;
  margin-bottom: 180px;
}

.hero-image-container {
  position: relative;
  max-width: 59.6%;
}

.hero-title {
  color: var(--color-grey-3);
}

.hero-text {
  margin: 24px 0;
  color: #e9ebf2;
}

.hero-btn-container {
  display: flex;
  justify-content: flex-start;
}

.hero-main-image {
  max-width: 111%;
  margin: -111px 0 0 9%;
}

.hero-main-image-mobile {
  display: none;
}

.partners-container {
  text-align: center;
}

.partners-slide {
  display: flex !important;
  justify-content: center;
}

.partners-icon {
  margin-right: 5%;
}

.partners-icon:last-child {
  margin-right: 0;
}

@media (max-width: 1045px) {
  .hero-main-image {
    max-width: 100%;
    margin-left: 5%;
  }

  .hero-text-container {
    max-width: 46%;
  }
}

@media (max-width: 875px) {
  .hero-container {
    flex-wrap: wrap;
  }

  .hero-text-container {
    max-width: 78%;
    margin-bottom: 5px;
  }

  .hero-main-image {
    margin: 5px 0 0 30%;
  }

  .partners-title {
    margin-right: 5%;
  }

  .partners-icon {
    margin-right: 3%;
  }
}

@media (max-width: 980px) {
  .hero-wrapper {
    margin-top: 112px;
    padding-bottom: 39px;
  }

  .hero-title {
    font-size: 45px;
    line-height: 53px;
  }

  .hero-text-container {
    max-width: 350px;
    margin-bottom: 5px;
    justify-content: center;
  }

  .hero-image-container {
    max-width: 100%;
  }

  .hero-container {
    flex-direction: column;
    justify-content: center;
  }

  .hero-main-image-mobile {
    display: block;
    max-width: 90%;
  }

  .hero-main-image {
    display: none;
  }

  .partners-icon {
    min-width: 130px;
    margin-right: 5%;
  }

  .partners-title {
    display: none;
  }

  .partners-container {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .partners-container::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 410px) {
  .hero-btn-container > .main-btn {
    padding: 10px 27px;
  }

  .partners-slide {
    justify-content: center;
  }
}
