.mobile-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 8.5%;
  background: linear-gradient(256.66deg, #131c3c 5.62%, #253674 97.31%);
  z-index: 100;
  overflow-y: scroll;
  font-size: 20px;
  line-height: 30px;
}

.close-menu-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.close-menu-svg {
  margin-right:8%;
}

.mobile-menu-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mobile-menu-container .nav .nav-element {
  font-size: 20px;
  line-height: 30px;
}

.mobile-menu-logo-container {
  display: flex;
  align-items: center;
  margin-bottom: 80px;
}

.mobile-menu-wrapper .nav {
  flex-direction: column;
}

.mobile-menu-wrapper .nav .nav-element {
  margin-right: 0;
  margin-bottom: 36px;
}

.mobile-language-selector {
  display: flex;
  justify-content: center;
  text-align: center;
  color: var(--color-grey-1);
  cursor: pointer;
}

.lang-arrow {
  margin-top: auto;
  margin-bottom: auto;
}

.mobile-language-selector:hover {
  color: var(--color-grey-3);
}
