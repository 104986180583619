.header {
  padding: 24px 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
}

.header.light{
  background-color: #ffffff ;
}

.header.sticky{
  background-color:#E9EBF2 ;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container {
  display: flex;
  align-items: center;
}

.header-logo {
  height: 40px;
  width: 40px;
}

.logo-text {
  margin-left: 4px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #e9ebf2;
}

.header-navigation {
  display: flex;
  align-items: center;
}

.header-lang {
  margin-left: 56px;
}

.header-burger-menu-icon {
  display: none;
  cursor: pointer;
}

@media (hover: hover) {
  .header-burger-menu-icon:hover > path {
    stroke: var(--color-primary);
  }
}

.header-burger-menu-icon.light > path {
  stroke: var(--color-grey-1);
}

.logo-text.light {
  color: var(--color-black);
}

@media (max-width: 980px) {
  .header-navigation {
    display: none;
  }
  .header-burger-menu-icon {
    display: block;
  }
}
