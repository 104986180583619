:root {
  --container-width: 1440px;

  --default-font: "Raleway", sans-serif;
  --secondary-font: "Lato", sans-serif;

  --color-primary: #cfa364;
  --color-primary-2: #d6b180;
  --color-secondary: #6491cf;
  --color-white: #ffffff;
  --color-red: #cf6d64;
  --color-green: #91cf64;
  --color-black: #131c3c;
  --color-grey-1: #71778a;
  --color-grey-2: #a7adc3;
  --color-grey-3: #e9ebf2;

  --size-title: 34px;
  --size-text: 16px;
}
