.vacancy-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2%;
    margin: 2%;
    border: 2px solid var(--color-grey-3);
    border-radius: 20px; 
}

.tags-container {
    display: flex;
    gap: 10px; 
    flex-wrap: wrap;
}
  
.tag {
  padding: 5px 10px;
  border: 2px solid var(--color-primary-2);
  border-radius: 20px; 
  background-color: transparent; 
}

.tag-grey {
    padding: 5px 10px;
    border: 2px solid var(--color-grey-3);
    border-radius: 20px; 
    background-color: transparent; 
  }

.job-details-button-text {
    padding-right: 5px;
}

@media (max-width: 980px) { /* Adjust this breakpoint as needed */
  .vacancy-container {
      flex-direction: column; /* Stack elements vertically on smaller screens */
      padding: 4%; /* Increase padding for better touch targets */
      margin: 4% auto; /* Adjust margin for mobile */
  }

  .tags-container {
      justify-content: center; /* Center tags for a better visual appearance */
      padding: 15px 0 10px;
  }

  .tag {
      padding: 8px 10px; /* Slightly larger padding for better readability */
  }
}