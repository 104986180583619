.quote-step-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quote-step-container {
  padding: 36px;
  background: rgba(217, 223, 244, 0.24);
  border-radius: 8px;
}

.quote-step-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 795px;
}

.input-container {
  width: 100%;
  max-width: 320px;
}

.input-container > .custom-input {
  width: 100%;
  margin-bottom: 20px;
}

.input-container > .custom-input > input {
  width: 100%;
}

.input-container > .custom-input:last-child {
  margin-bottom: 0;
}

.input-container > .custom-input > label {
  background-color: transparent;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 51%, rgba(217, 223, 244, 0) 100%);
}

@media (max-width: 760px) {
  .quote-step-container {
    flex-direction: column;
    align-items: center;
  }

  .quote-step-container > h5 {
    text-align: center;
    margin-bottom: 24px;
  }
}
