.our-services-wrapper {
  position: relative;
  width: 100%;
  padding: 136px 0 112px 0;
  background-color: #ffffff;
}

.our-services-container {
  display: flex;
  flex-direction: column;
}

@media (max-width: 980px) {
  .our-services-wrapper {
    padding-top: 72px;
    padding-bottom: 56px;
  }
}
