.dropdown {
  position: relative;
  width: 37px;
  z-index: 2;
  background: none;
  color: var(--color-grey-1);
}

.dropdown-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.dropdown:hover .dropdown-body {
  display: block;
}

.dropdown-body {
  position: absolute;
  display: none;
}

.dropdown-item {
  padding-top: 10px;
  cursor: pointer;
}

.dropdown-item:last-child {
  padding-bottom: 10px;
}

.dropdown-item.light:hover {
  color: var(--color-black);
}

.dropdown-item.dark:hover {
  color: var(--color-grey-3);
}

.icon {
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
  font-size: 13px;
  color: #91a5be;
}

.dropdown:hover .icon {
  transform: rotate(180deg);
}

.lang-footer {
  top: -35px;
}
