.blog-card-container {
  width: 100%;
  max-width: 359px;
  padding: 18px;
  margin: 0 auto;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.blog-card-container:hover {
  transform:scaleX(1.02)
}

.blog-card-subtitle {
  color: var(--color-primary);
  margin-top: 16px;
}

.blog-card-title {
  margin-top: 16px;
  margin-bottom: 16px;
}

.blog-card-context {
  margin-bottom: 28px;
}

.blog-card-image {
  width: 100%;
}
