body {
  font-family: var(--default-font);
  color: var(--color-black);
  background: linear-gradient(256.66deg, #131c3c 5.62%, #253674 97.31%);
}

.container {
  max-width: 1440px;
  margin: auto;
  padding: 0 11%;
}

h1 {
  font-weight: 700;
  font-size: 60px;
  line-height: 70px;
  letter-spacing: 3px;
}

.title {
  font-weight: 700;
  font-size: 60px;
  line-height: 70px;
  letter-spacing: 3px;
}

h2 {
  font-weight: 800;
  font-size: 34px;
  line-height: 140%;
}

h3 {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}

h4 {
  font-weight: 600;
  font-size: 22px;
  line-height: 150%;
}

h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
}

h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

b {
  font-family: var(--secondary-font);
  font-weight: 600;
}

p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

button {
  font-family: var(--default-font);
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.noscroll {
  overflow: hidden;
}

@media (max-width: 992px) {
  .container {
    padding: 0 9%;
  }
}

@media (max-width: 980px) {
  .container {
    padding: 0 25px;
  }

  .title-mobile-size {
    font-size: 28px;
    line-height: 39px;
  }

  .subtitle-mobile-size {
    font-size: 14px;
    line-height: 21px;
  }
}

@media (min-width: 1600px) {
  .container {
    padding: 0 7%;
  }
}


.Toastify__toast-body{
    white-space: pre-line;
}