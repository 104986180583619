.roi-calculator-container {
    max-width: var(--container-width);
    margin: auto;
    padding: 20px;
    padding-bottom: 5%;
    font-family: var(--default-font);
    color: var(--color-black);
}

.roi-calculator-header-container {
    color: var(--color-white);
    padding-bottom: 5%;
    padding-top: 4%;
    text-align: center;
}

.roi-header-text {
    padding: 10px 15%;
}

.roi-calculator-results-header {
    color: var(--color-white);
    padding-bottom: 2%;
    padding-top: 3%;
    text-align: center;
}

.email-section {
    color: var(--color-white);
    text-align: center;
    margin-top: 10px;
    padding: 0px 10px;
}

.roi-calculator-checkbox-container{
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid var(--color-grey-2);
    border-radius: 5px;
    color: var(--color-white);
    font-family: var(--secondary-font);
    font-size: var(--size-text);
    display: flex;
    align-items: center;
}

.roi-calculator-checkbox {
    margin-right: 10px;
}

.roi-calculator-results-container {
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid var(--color-grey-2);
}

.form-input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid var(--color-grey-2);
    border-radius: 5px;
    font-family: var(--secondary-font);
    font-size: var(--size-text);
}

.form-input-in-group {
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    border: 1px solid var(--color-grey-2);
    border-radius: 5px;
    font-family: var(--secondary-font);
    font-size: var(--size-text);
}

.form-input-error {
    border: 1px solid orange;
}

.padding-bottom-5 {
    padding: 20px;
}

.form-input-error-select {
    border: 1px solid orange;
    border-radius: 5px;
}

/* ROICalculator.css - Add these styles to your CSS file */

.form-group-roi-calculator {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.required-star {
    color: orange;
    margin-left: 4px;
}

.roi-calculator-footer {
    height: 12vh;
    background-color: var(--color-grey-3);
}

.calculate-button {
    width: 100%;
    padding: 15px;
    margin: 10px 0;
    background-color: var(--color-primary);
    color: var(--color-white);
    border: none;
    border-radius: 5px;
    font-family: var(--default-font);
    font-size: var(--size-text);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.calculate-button:hover {
    background-color: var(--color-primary-2);
}

.data-grid {
    margin-top: 20px;
    border-collapse: collapse;
    width: 100%;
}

.data-grid-header,
.data-grid-cell {
    border: 1px solid var(--color-grey-2);
    text-align: left;
    padding: 8px;
    background-color: var(--color-white);
}

.data-grid-header {
    background-color: var(--color-grey-1);
    color: var(--color-white);
}

.data-grid-row:nth-child(odd) {
    background-color: var(--color-grey-3);
}

.form-label {
    padding-left: 10px;
    padding-bottom: 10px;
    color: var(--color-white);
}

.first-cell-summary {
    border-left: 2px solid var(--color-green) !important;
}

.last-cell-summary {
    border-right: 2px solid var(--color-green) !important;
}

/* Apply top and bottom borders to all cells for continuous borders around the row */
.data-grid-cell-summary {
    border-top: 2px solid var(--color-green);
    border-bottom: 2px solid var(--color-green);
    border-left: 1px solid var(--color-grey-2);
    border-right: 1px solid var(--color-grey-2);
    padding: 8px;
    background-color: var(--color-white);
    font-weight: bold;
}

@media (max-width: 768px) {
    .data-grid-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        margin-top: 20px;
    }

    .data-grid {
        min-width: 600px; /* Set a minimum width to ensure content does not squeeze */
        border-collapse: collapse;
    }
}
