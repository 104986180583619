.content-step-container {
  padding: 0px 59px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.upload-or-text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.content-title {
  align-self: flex-start;
  margin: 76px 0 25px 0;
  font-size: 20px;
}

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 214px;
  width: 100%;
  max-width: 454px;
  background-color: #d9dff43d;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23A7ADC34D' stroke-width='1' stroke-dasharray='9%2c9' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  box-sizing: border-box;
  border-radius: 8px;
}

.upload-container.error {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23CF6D64FF' stroke-width='1' stroke-dasharray='9%2c9' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.upload-container-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-container-text > p {
  margin: 8px 0;
  color: var(--color-grey-2);
  font-size: 14px;
line-height: 150%;
}

.upload-container-text > .main-btn {
  margin: 0;
}

.content-text-field {
  position: relative;
  width: 100%;
  max-width: 454px;
  min-height: 180px;
  max-width: 454px;
  padding: 24px 0 0px 24px;
  background: rgba(217, 223, 244, 0.24);
  border: 1px solid rgba(167, 173, 195, 0.3);
  border-radius: 8px;
}

.content-step-next{
  margin-left: auto;
}

.content-text-field.error {
  border: 1px solid var(--color-red);
}

.upload-error {
  padding-top: 10px;
  text-align: center;
  align-self: center;
  color: var(--color-red);
}

.text-input {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 180px;
  max-width: 454px;
  padding: 0 24px 35px 0;
  background: transparent;
  border: 0;
  color: var(--color-black);
  resize: vertical;
  font-family: var(--default-font);
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}

.text-input::-webkit-resizer {
  background-image: url("../../../../images/icons/resizeIcon.svg");
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
}

.fade {
  position: absolute;
  bottom: 3px;
  left: 3px;
  width: 94%;
  height: 51px;
  background: linear-gradient(180deg, rgba(246, 248, 253, 0.1) 0%, #f6f8fd 63.21%);
}

.text-input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--color-grey-2);
}

.text-input:focus-visible {
  outline: none;
}

.text-input:focus {
  outline: none;
}

.uploaded-list {
  width: 100%;
  height: 127px;
  padding: 0 24px 10px 24px;
  overflow-y: scroll;
}

.uploaded-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(167, 173, 195, 0.3);
  padding-bottom: 6px;
  margin-top: 16px;
}

.uploaded-item > svg {
  cursor: pointer;
  flex: none;
  margin-left: 5px;
}

.upload-or-text-container > p {
  margin: 0 4.8%;
}

.file-details {
  display: flex;
  align-items: center;
}

.file-details > li {
  word-break: break-all;
}

.file-details > svg {
  margin-right: 8px;
  flex: none;
}

.uploaded-files > .icon-right-btn > svg {
  display: none;
}

.uploaded-files {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploaded-files > .fade {
  bottom: 44px;
  height: 27px;
  background: linear-gradient(180deg, rgba(246, 248, 253, 0.1) 0%, #f6f8fd 83.21%);
}

.uploaded-files > .icon-right-btn {
  margin-top: 24px;
}

.uploaded-list::-webkit-scrollbar,
.text-input::-webkit-scrollbar {
  width: 12px;
}

.text-input::-webkit-scrollbar-track {
  margin: 5px 0;
}

.uploaded-list::-webkit-scrollbar-thumb,
.text-input::-webkit-scrollbar-thumb {
  background-image: url("../../../../images/icons/scrollIcon.svg");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 2px 0px 0px 2px;
  border-left: 7px solid #f6f7fc;
}

.remove-icon:hover > path:first-child {
  fill: var(--color-grey-1);
}

.main-btn.mobile {
  display: none;
}

.or-text{
  font-size: 20px;
  line-height: 150%;
}

@media (max-width: 910px) {
  .steper-container {
    width: 100%;
  }

  #RFS-StepperContainer {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 810px) {
  .upload-or-text-container {
    flex-direction: column;
  }

  .content-step-container {
    padding: 0 15px;
  }

  .content-title {
    align-self: unset;
    text-align: center;
  }

  .upload-or-text-container > p {
    font-size: 20px;
    margin: 18px 0;
  }

  .upload-container-text > h3,
  .upload-container-text > p {
    display: none;
  }

  .upload-container-text > .main-btn {
    display: none;
  }

  .main-btn.mobile {
    display: block;
  }
}
