.blog-wrapper {
  position: relative;
  width: 100%;
  padding: 48px 0 170px 0;
  background-color: #ffffff;
}

.author-image {
  border-radius: 50%;
}

.blog-container {
  display: flex;
  flex-direction: column;
}

.blog-title-container {
  width: 100%;
}

.blog-inner-paragraph {
  text-indent: 5%;
}

.blog-subtitle {
  color: var(--color-primary);
}

.blog-title {
  max-width: 90%;
  margin: 4px 0 24px;
}

.blog-inner-text-container {
  margin-top: 1.5%;
  margin-bottom: 2%;
}

.blog-inner-subtitle {
  font-weight: 600;
  font-size: 22px;
}

.blog-content-container {
  display: flex;
}

.blog-current-container {
  display: flex;
  flex-direction: column;
  max-width: 65%;
  margin-right: 10%;
}

.blog-image {
  max-width: 95%;
  height: auto;
  padding-bottom: 24px;
}

.blog-inner-image {
  max-width: 95%;
  height: auto;
  padding-bottom: 6%;
  padding-top: 6%;
}

.blog-author-container {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.author-name {
  margin: 0 18px;
}

.blog-date {
  color: var(--color-grey-1);
}

.blog-text > p {
  margin-bottom: 20px;
}

.more-blogs-container {
  display: flex;
  flex-direction: column;
  width: 25%;
  max-height: 970px;
}

.blogs-scroll-container {
  overflow-y: scroll;
  overflow-x: hidden;
}

.more-articles {
  padding: 20px 0 20px 16px;
  color: var(--color-grey-1);
  font-weight: 600;
  font-size: 22px;
  line-height: 150%;
}

.blogs-scroll-container::-webkit-scrollbar {
  width: 8px;
}

.blogs-scroll-container::-webkit-scrollbar-track {
  border-right: 1px solid rgba(167, 173, 195, 0.4);
}

.blogs-scroll-container::-webkit-scrollbar-thumb {
  background-color: var(--color-secondary);
  border-radius: 2px 0px 0px 2px;
}

@media (max-width: 1200px) {
  .blog-current-container {
    margin-right: 0;
    max-width: 100%;
  }

  .blog-content-container {
    flex-direction: column;
  }

  .blog-image {
    align-self: center;
    padding-bottom: 24px;
    aspect-ratio: 2.5/1;
  }

  .blogs-scroll-container {
    overflow-y: hidden;
    height: 100%;
  }

  .more-blogs-container {
    align-self: center;
    width: 350px;
  }
}

@media (max-width: 540px) {
  .blog-wrapper {
    padding: 90px 0 56px 0;
  }

  .blog-title-container {
    text-align: center;
  }

  .blog-title.title {
    margin: 0 auto 24px;
    max-width: 286px;
    font-weight: 800;
    font-size: 28px;
    line-height: 39px;
    letter-spacing: normal;
  }

  .blog-image {
    width: 100%;
    height: 40%;
  }

  .more-blogs-container {
    margin-top: 15px;
    width: 350px;
  }

  .more-blogs-container .blog-card-container {
    margin-left: 0;
  }
  .blogs-scroll-container > .slick-slider > .custom-dots {
    bottom: 0;
  }
}
