/* Base styles */

.careers-footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

.careers-container {
    padding: 5% 15% 8%;
    background-color: var(--color-white);
}

.careers-heading-h1 {
    line-height: 1.2; /* Example base line height */
    margin: 0 0 20px; /* Adjust as necessary */
    color: var(--color-white);
    text-align: center;
}

.go-back-container {
    display: flex;
    align-items: center;
}

.go-back-text{
    padding-left: 5px;
}

.vacancy-description-container{
    justify-content: center;
}

.vacancy-header-container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5%;
}

.vacancy-body-container{
    display: flex;
    flex-direction: column;
}

.careers-section-container {
    display: flex;
    flex-direction: column;
    max-width: 70%;
    margin: auto;
    padding: 20px;
    background-color: var(--color-grey-3);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 12px;
}

.careers-footer-btn {
    background-color: var(--color-primary);
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
}

.careers-footer-btn:hover {
    background-color: #d4af37;
}

.careers-section-container h4 {
    font-size: 24px;
    margin-bottom: 16px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 8px;
}

.careers-section-container p,
.careers-section-container ul,
.careers-section-container li {
    line-height: 1.6;
    margin-bottom: 12px;
}

.careers-section-container ul {
    list-style-type: disc;
    list-style-position: inside;
    padding-left: 20px;
}

.careers-section-container li {
    margin-bottom: 8px;
}

/* Responsive styles for mobile devices */
@media (max-width: 980px) {
    .vacancy-header-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-bottom: 5%;
        margin-left: 8%;
    }

    .careers-section-container {
        max-width: 95%; /* Allow more width on smaller screens */
        padding: 15px; /* Adjust padding for mobile */
        margin-top: 10px;
    }

    .careers-section-container h4 {
        font-size: 20px; /* Adjust font size for smaller screens */
        margin-bottom: 12px;
    }

    .careers-footer-btn {
        padding: 8px 15px; /* Adjust button padding for smaller screens */
        font-size: 16px; /* Adjust font size for readability */
    }

    .careers-footer-container {
        margin-top: 10%; /* Adjust spacing at the top for mobile */
    }

    .careers-section-container ul,
    .careers-section-container p,
    .careers-section-container li {
        padding-left: 15px; /* Adjust list padding for alignment */
        margin-bottom: 10px; /* Adjust spacing for compactness */
    }

    .careers-heading-h1 {
        font-size: 2.1rem; /* Smaller font size for mobile */
        line-height: 1.3; /* Adjusted line height for mobile readability */
        color: var(--color-white);
        text-align: center;
    }

    .careers-container {
        padding: 5% 5% 8%;
        background-color: var(--color-white);
    }

    .go-back-container {
        display: flex;
        align-items: center;
        padding: 0px 10px 0px 0px;
        border: 2px solid var(--color-grey-3);
        border-radius: 20px; 
        background-color: transparent; 
    }
}
