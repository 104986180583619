.language-input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.language-input-container {
  display: flex;
  width: 100%;
  max-width: 795px;
  padding: 32px 36px 32px;
  background: rgba(217, 223, 244, 0.24);
  border-radius: 8px;
}

.language-input-container > h5 {
  margin-right: 5%;
}

.dropdown-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 580px;
}

.dropdown-selected-container {
  width: 100%;
  max-width: 282px;
}

.selected-container {
  width: 100%;
  max-width: 282px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
}

.selected-lang {
  width: 100px;
  height: 30px;
}

.chip-container {
  display: flex;
  align-items: center;
  padding: 3px 3px 3px 6px;
  margin: 0 5px 4px 5px;
  border: 1px solid rgba(167, 173, 195, 0.3);
  border-radius: 16px;
  cursor: pointer;
}

.chip-container > p {
  padding-right: 6px;
  font-size: 12px;
  line-height: 18px;
}

.chip-container:hover > svg > path:first-child {
  fill: var(--color-grey-1);
}

@media (max-width: 860px) {
  .language-input-container > h5 {
    margin-bottom: 4px;
  }

  .language-input-container {
    flex-direction: column;
    align-items: center;
  }

  .dropdown-container {
    flex-direction: column;
    align-items: center;
  }

  .translate-dropdown:first-child {
    margin-right: 0;
    margin-top: 20px;
  }
}
