.checkbox-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox-container {
  display: flex;
  margin-bottom: 16px;
}

.checkbox-container > label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.checkbox-container > label::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1.5px solid var(--color-grey-1);
  border-radius: 7px;
  margin-right: 15px;
  background-repeat: no-repeat;
  background-position: center center;
}

input[type="checkbox"] + label {
  cursor: pointer;
}

.checkbox-input:checked + label::before {
  border-color: var(--color-secondary);
  background-image: url(../../images/icons/checkboxSelected.svg);
}

.checkbox-input:not(:disabled):not(:checked) + label:hover::before {
  border: 1.5px solid var(--color-secondary);
}

.checkbox-input:disabled + label::before {
  border: 1.5px solid var(--color-grey-1);
}

.checkbox-container > svg {
  margin-left: 8px;
}

.checkbox-container > svg:hover > path {
  stroke: var(--color-grey-1);
}

.checkbox-container > svg:focus {
  outline: unset;
}

.tip{
  max-width: 200px;
}
