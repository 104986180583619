.number-counter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
}

.number-counter-label {
    font-size: 20px;
}

.number-counter-digits {
    font-weight: 800;
    margin-bottom: 15px;
    font-size: 30px;
}

@media (max-width: 980px) {
    .number-counter-label {
        font-size: 15px;
    }
}