/* General container styles */
.contact-cards {
    padding: 160px 0 240px;
    background-color: var(--color-white);
}

.contact-us-card-container {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    padding: 5%;
    align-items: center;
}

.contact-us-cards-container {
    margin: 10% 0 2%;
}

.for-btn {
    display: flex;
    flex-direction: row !important;
    align-items: center;
}

@media (max-width: 980px) {
    .contact-cards {
        padding: 96px 0 140px;
        background-color: var(--color-white);
    }

    .contact-cards-subtitle {
        margin-bottom: 0;
    }

    .contact-cards-title {
        margin-bottom: 36px;
    }

    .mail-us-container {
        margin-top: 30% !important;
    }
}

.link-underline {
    color: var(--color-primary);
    border-bottom: 2px solid var(--color-primary);
}

.mail-us-container {
    display: flex;
    margin-top: 10%;
}

.mail-us-container .devider-mail {
    margin-left: 5px;
}

/* Link and arrow styles */
.style-1 {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    height: 2rem;
    line-height: 2rem;
    color: #000;
    transition: transform 0.3s ease;
}

.style-1 .arrow-icon {
    margin-left: 8px;
    transition: transform 0.3s ease;
}

.style-1 .arrow-icon--circle {
    transition: stroke-dashoffset 0.3s ease;
    stroke-dasharray: 95;
    stroke-dashoffset: 95;
}

.style-1:hover .arrow-icon {
    transform: translateX(5px);
}

.style-1:hover .arrow-icon--circle {
    stroke-dashoffset: 0;
}
