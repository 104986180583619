.main-btn {
  padding: 10px 33px;
  margin-right: 12px;
  border: none;
  background-color: var(--color-primary);
  border-radius: 8px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--color-white);
}

@media (hover: hover) {
  .main-btn:hover {
    background-color: #ce923b;
  }
}

.main-btn:disabled {
  background-color: #d2b78f;
}
