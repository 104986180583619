.instant-quote-page-wrapper {
  position: relative;
  width: 100%;
  padding: 120px 25px 0;
  background-color: #ffffff;
}

.instant-quote-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.instant-quote-page-subtitle {
  color: var(--color-grey-1);
  font-size: 20px;
  line-height: 150%;
}

.steper-container {
  width: 90%;
}

#RFS-Label {
  font-family: var(--default-font);
  font-weight: 400;
}

#RFS-StepButton {
  padding-bottom: 4px;
}

#RFS-StepButton.completed {
  padding-bottom: 0;
}

.navigation-button-container {
  margin-top: 34px;
  padding-bottom: 56px;
  width: 100%;
  max-width: 794px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 640px) {
  .instant-quote-page-wrapper{
    padding: 36px 0 0;
  }

  .instant-quote-page-wrapper .container{
    padding: 0 20px;
  }

  .steper-container {
    width: 100%;
  }

  #RFS-StepperContainer {
    padding: 24px 0;
  }
}
