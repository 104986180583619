.careers-list-heading {
    text-align: center;
    margin-bottom: 4%;
}

.careers-heading {
    text-align: center;
    color: var(--color-grey-3);
    margin-bottom: 2%;
}

.careers-container {
    padding: 160px 15% 240px;
    background-color: var(--color-white);
}

.careers-container-headings {
    display: flex;
    justify-content: center;
}

.language-note {
    display: inline-block;
    margin-left: 0px; /* Adjust spacing to the right of the heading */
    transform: rotate(12deg); /* Tilt the sentence */
    position: relative; /* Adjust if you need more specific positioning */
    white-space: nowrap; /* Keep the sentence in one line */
    font-style: italic; /* Optional: style */
  }

.careers-container-primary::before {
    content: "";
    position: absolute;
    bottom: 0; /* Align to the bottom of the container */
    top: -5%;
    left: 50%; /* Center horizontally */
    transform: translate(-50%, 0); /* Adjust for precise centering */
    width: 1100px;
    height: 1100px;
    background-image: url('/public/logo.svg'); /* Use the correct path */
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover; /* Or adjust to contain for full visibility */
    opacity: 0.1; /* Adjust for desired transparency */
    pointer-events: none; /* Click-through */
    z-index: -1; /* Ensure it's under the content */
}

.careers-container-primary {
    padding: 160px 15% 210px;
    background-color: linear-gradient(256.66deg, #131c3c 5.62%, #253674 97.31%);
}

@media (max-width: 980px) {
    .careers-container {
        padding: 160px 5% 240px;
        background-color: var(--color-white);
    }

    .careers-container-primary {
        padding: 160px 15% 210px;
        background-color: linear-gradient(256.66deg, #131c3c 5.62%, #253674 97.31%);
    }

    .careers-container-primary::before {
        display: none;
    }
}