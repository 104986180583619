.link-animated {
  color: var(--color-primary); 
  text-decoration: none; 
  position: relative;
  transition: color 0.3s ease;
}

.link-animated::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--color-primary);
  transition: width 0.5s ease, left 0.5s ease;
}

.link-animated:hover::after, .link-animated:focus::after {
  width: 0; 
  left: 100%; 
}


.how-it-works-wrapper {
  padding-bottom: 100px;
  background-color: var(--color-white);
}

.how-it-works-wrapper > .container {
  padding-right: 78px;
}

.how-it-works {
  display: flex;
  align-items: center;
}

.how-it-works-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 442px;
  margin-left: 9.5%;
}

.how-it-works-image {
  width: 53.7%;
}

.how-it-works-image-mobile {
  display: none;
}

.how-it-works-subtitle {
  color: var(--color-primary);
}

.how-it-works-list-item {
  margin: 10px;
}
.how-it-works-list-item > p {
  font-size: 110%;
}


.how-it-words-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.how-it-works-btn {
  margin-top: 32px;
}

@media (max-width: 980px) {
  .how-it-works-image {
    display: none;
  }

  .how-it-works-wrapper > .container {
    padding-right: 25px;
  }

  .how-it-works-image-mobile {
    display: block;
    margin-bottom: 36px;
  }

  .how-it-works-text-container,
  .how-it-works-image-mobile {
    width: 100%;
    max-width: 360px;
    margin-left: 0;
  }

  .how-it-works {
    justify-content: center;
  }

  .how-it-works-list-item {
    margin-bottom: 12px;
  }

  .how-it-works-btn .main-btn {
    width: 100%;
    max-width: 360px;
    margin-right: 0;
  }
}
