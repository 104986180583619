.translate-dropdown {
  position: relative;
  width: 100%;
  max-width: 282px;
}

.translate-dropdown:first-child {
  margin-right: 16px;
}

.translate-dropdown-label {
  position: absolute;
  top: -8px;
  left: 16px;
  padding: 0 4px;
  color: var(--color-grey-1);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 51%, rgba(217, 223, 244, 0) 100%);
  font-size: 12px;
  line-height: 150%;
}

.translate-dropdown-selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 12px;
  border: 1px solid rgba(167, 173, 195, 0.3);
  border-radius: 8px;
  background-color: var(--color-white);
  cursor: pointer;
  text-overflow: ellipsis;
}

.translate-dropdown-selected > svg {
  min-width: 16px;
}

.translate-dropdown-selected > p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.place-holder {
  color: var(--color-grey-1);
}

.lang-dropdown-body {
  display: flex;
  position: absolute;
  top: 45px;
  max-width: 282px;
  width: 100%;
  max-height: 152px;
  flex-direction: column;
  justify-content: space-between;
  padding: 18px 0;
  border: 1px solid var(--color-grey-3);
  border-radius: 8px;
  background-color: #ffffff;
  z-index: 50;
  overflow-y: scroll;
}

.lang-dropdown-body > p {
  padding: 0 10px;
}

.lang-dropdown-body::-webkit-scrollbar {
  width: 5px;
}

.lang-dropdown-body::-webkit-scrollbar-thumb {
  background-color: var(--color-grey-1);
  border-radius: 2px 0px 0px 2px;
}

.dropdown-lang-item {
  cursor: pointer;
  font-weight: 400;
  padding-left: 18px;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 6px;
}

.dropdown-lang-item:last-child {
  margin-bottom: 0;
}

.dropdown-lang-item:hover {
  background-color: #fafafa;
}

.lang-dropdown-body.open {
  display: flex;
}
