.icon-right-btn {
  display: flex;
  align-items: center;
  color: var(--color-primary);
  background-color: transparent;
  border: none;
}

.icon-right-btn p {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

.icon-right-btn.dark {
  color: #ffffff;
}

.icon-right.dark {
  stroke: #ffffff;
}

@media (hover: hover) {
  .icon-right-btn:hover {
    color: #ce923b;
  }

  .icon-right-btn:hover .icon-right,
  .icon-right-btn.dark:hover .icon-right.dark {
    stroke: #ce923b;
  }
}

.icon-right-btn:disabled,
.icon-right-btnbutton[disabled] {
  color: var(--color-grey-2);
}
