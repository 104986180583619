.icon-left-btn {
  display: flex;
  align-items: center;
  color: var(--color-grey-1);
  background-color: transparent;
  border: none;
}

.icon-left-btn p {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

@media (hover: hover) {
  .icon-left-btn:hover {
    color: var(--color-black);
  }
}

.icon-left-btn:disabled {
  color: var(--color-grey-2);
}
