.not-found-wrapper {
  position: relative;
  width: 100%;
  padding-top: 241px;
  padding-bottom: 140px;
  background-color: #ffffff;
}

.not-found-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.not-found-text-container {
  max-width: 43%;
}

.not-found-title {
  font-size: 60px;
  line-height: 70px;
  letter-spacing: 3px;
  white-space: pre-line;
}

.not-found-text-container > p {
  margin: 24px 0;
  font-size: 20px;
  line-height: 30px;
}

.not-found-text-container > button {
  width: 226px;
}

.not-found-image {
  max-width: 51%;
  margin-top: -79px;
}

@media (max-width: 768px) {
  .not-found-wrapper {
    padding-top: 90px;
    padding-bottom: 56px;
  }

  .not-found-container {
    flex-direction: column;
  }

  .not-found-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90%;
  }

  .not-found-title {
    text-align: center;
    font-size: 34px;
    line-height: 47px;
  }

  .not-found-text-container > p {
    margin-top: 12px;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
  }

  .not-found-text-container > button {
    width: 332px;
  }

  .not-found-image {
    margin-top: 0;
    max-width: 75%;
  }
}

@media (max-width: 980px) {
  .not-found-image {
    margin-left: -53px;
    max-width: 399px;
  }
}

@media (max-width: 400px) {
  .not-found-image {
    margin-left: -40px;
  }
}
