.latest-blog-posts {
  padding: 250px 0;
  background-color: var(--color-white);
}

.latest-blog-posts-subtitle {
  margin-bottom: 4px;
  color: var(--color-primary);
}

.latest-blog-posts-title {
  margin-bottom: 80px;
}

.blog-cards-container .custom-dots{
  bottom: -18px;
}

@media (max-width: 980px) {
  .latest-blog-posts {
    padding: 96px 0 56px;
  }

  .latest-blog-posts-title {
    margin-bottom: 36px;
  }

  .latest-blog-posts-subtitle {
    margin-bottom: 0;
  }
}
