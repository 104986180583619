/* Base Styles: Optimized for Mobile Devices */
.our-team-container {
    display: flex;
    flex-direction: column;
    background-color: transparent;
}

.our-team-header-container {
    color: var(--color-white);
    text-align: center;
    padding: 60% 15%;
    margin-bottom: 3%; /* Adjusted for mobile */
}

.our-team-people-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-white);
    margin-top: 3%;
    padding: 5% 5%;
    border-top: 0.5rem solid var(--color-grey-3); /* Adjusted for mobile */
    border-bottom: 1rem solid var(--color-grey-3); /* Adjusted for mobile */
}

.our-team-members-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center; 
    align-items: center;
    background-color: var(--color-white);
}

.our-team-people-heading {
    text-align: center;
    margin-bottom: 3%;
    padding: 10px 10%;
}

.link-button {
    background-color: var(--color-primary);
    color: white;
    border: none;
    border-radius: 20px;
    padding: 8px 15px; /* Adjusted for mobile */
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
}

.link-button:hover {
    background-color: var(--color-primary-2);
}

.our-team-join-container {
    background-color: var(--color-grey-3);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 3%;
    padding-bottom: 12%;
}

.our-team-join-heading {
    margin-bottom: 1.5%;
}

/* Media Queries for Tablet and Desktop Screens */
@media (min-width: 768px) {
    .our-team-header-container {
        height: auto;
        padding: 10% 15%;
        margin-bottom: 5%;
    }

    .our-team-people-container {
        padding: 5% 5%;
        border-top: 1rem solid var(--color-grey-3);
        border-bottom: 2rem solid var(--color-grey-3);
    }

    .our-team-members-container {
        justify-content: center;
    }

    .our-team-join-container {
        padding-bottom: 6%;
    }

    .link-button {
        padding: 10px 20px; /* Larger for easier clicking on non-touch devices */
    }
}
