.testimonials {
  padding: 160px 0 240px;
  background-color: var(--color-white);
}

.testimonials-subtitle {
  margin-bottom: 4px;
  color: var(--color-primary);
}

.testimonials-title {
  margin-bottom: 80px;
}

.custom-dots {
  position: absolute;
  bottom: -55px;
  width: 100%;
  text-align: center;
}

.custom-dots li {
  display: inline-block;
  margin-right: 6px;
}

.custom-dots li button {
  width: 8px;
  height: 8px;
  padding: 0;
  border-radius: 50%;
  background-color: var(--color-grey-3);
  border: 0;
  cursor: pointer;
  color: transparent;
}

.custom-dots .slick-active button {
  width: 32px;
  height: 8px;
  border-radius: 0%;
  background: url("../../../images/icons/slickDotActive.svg");
  transition: 0.3s;
}

@media (max-width: 980px) {
  .testimonials {
    padding: 96px 0 140px;
    background-color: var(--color-white);
  }

  .testimonials-subtitle {
    margin-bottom: 0;
  }

  .testimonials-title {
    margin-bottom: 36px;
  }
}
