.why-choose-us-wraper {
  position: relative;
  padding: 100px 0;
  background-color: var(--color-white);
}

.arrow-down-background {
  position: absolute;
  top: -25px;
  left: 153px;
}
.arrow-down {
  position: absolute;
  top: -10px;
  left: 205px;
  transform: rotate(180deg);
  cursor: pointer;
}

.arrow-down:hover > path {
  stroke: #ce923b;
}

.why-choose-us {
  display: flex;
  align-items: center;
}

.stats-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0% 5%;
  background-color: var(--color-grey-3);
  padding: 20px 0px;
  border-radius: 10px;
}

.why-choose-us-text-container {
  width: 40.5%;
  margin-right: 4.5%;
}

.why-choose-us-subtitle {
  margin-bottom: 4px;
  color: var(--color-primary);
}

.why-choose-us-heading {
  margin: 18px 0;
}

.why-choose-us-image {
  width: 54.5%;
}

@media (max-width: 875px) {
  .arrow-down-background {
    left: 103px;
  }

  .arrow-down {
    left: 155px;
  }
}

@media (max-width: 980px) {
  .arrow-down-background,
  .arrow-down {
    display: none;
  }

  .why-choose-us-wraper {
    padding: 50px 0;
  }

  .why-choose-us {
    flex-direction: column;
  }

  .why-choose-us-text-container,
  .why-choose-us-image {
    width: 100%;
    max-width: 359px;
  }

  .why-choose-us-text-container {
    margin-bottom: 28px;
  }

  .why-choose-us-subtitle {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 21px;
  }

  .why-choose-us-heading {
    margin: 16px 0;
    font-size: 20px;
    line-height: 30px;
  }
}
