.pop-up-blur {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 160;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.28);
  backdrop-filter: blur(4px);
}

.book-a-demo-wrapper {
  position: fixed;
  z-index: 150;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 6%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: 739px;
  height: 100%;
  max-height: 729px;
  padding: 48px;
  overflow-y: auto;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 2px 26px rgba(79, 70, 70, 0.1);
}

.pop-up-close-icon {
  overflow: unset;
  align-self: flex-end;
  cursor: pointer;
}

.pop-up-close-icon:hover > path {
  stroke: var(--color-black);
}

.pop-up-title {
  margin-top: 48px;
  margin-bottom: 60px;
}

.pop-up-form {
  display: flex;
  flex-direction: column;
}

.date-input {
  width: 320px;
  height: 40px;
  padding: 0 12px;
  border: 1px solid rgba(167, 173, 195, 0.3);
  border-radius: 8px;
}

.date-input::placeholder {
  color: var(--color-grey-2);
}

.date-label {
  z-index: 1;
}

.date-input:focus {
  outline: none;
  border-color: var(--color-grey-1);
}

.date-input.input-error:focus-visible,
.date-input.input-error {
  outline: none;
  border-color: var(--color-red);
}

@media (max-width: 720px) {
  .book-a-demo-wrapper {
    top: 0;
  }

  .pop-up-title {
    margin-top: 64px;
  }
}
