.footer {
  position: relative;
  padding-top: 80px;
  padding-bottom: 31px;
  background: linear-gradient(255.52deg, #131c3c -37.02%, #253674 143.02%);
}

.dropdown {
  position: relative;
  width: 37px;
  z-index: 2;
  background: none;
  color: var(--color-grey-1);
}

.footer-flex {
  display: flex;
  justify-content: space-between;
}

.arrow-up-background {
  position: absolute;
  top: -25px;
  right: 50px;
}

.arrow-up {
  position: absolute;
  top: -9px;
  right: 99px;
  cursor: pointer;
}

.arrow-up:hover > path {
  stroke: #ce923b;
}

.footer-navigation-container {
  align-items: center;
  margin-bottom: 40px;
}

.footer-title {
  color: var(--color-grey-3);
}

.write-us-container {
  align-items: center;
  margin-bottom: 57px;
  border-bottom: 1px solid rgba(113, 119, 138, 0.4);
  padding-bottom: 57px;
  height: 35px;
}

.social-media-title {
  margin-right: 36px;
  font-family: "Popins", sans-serif;
  color: var(--color-grey-2);
  font-size: 12px;
  line-height: 19px;
}

.social-link:nth-child(-n + 3) {
  margin-right: 24px;
}

.social-link:hover .social-icon > path {
  fill: var(--color-grey-3);
}

.copyright-container {
  margin-top: 56px;
}

.copyright {
  color: var(--color-grey-3);
  font-size: 14px;
}

.social-media-container {
  margin-left: auto;
}

@media (max-width: 980px) {
  .footer-container {
    display: none;
  }
}
