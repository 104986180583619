.instant-quote-wrapper {
  padding: 78px 0;
  background-color: #fafbfe;
  background-image: url(../../../images/instatnQuoteBgImage.png);
  background-position: right;
  background-repeat: no-repeat;
}

.instant-quote-text {
  max-width: 504px;
  margin: 24px 0;
}

@media (max-width: 980px) {
  .instant-quote-wrapper {
    padding: 65px 0;
    background-image: url(../../../images/instatnQuoteBgImageSmall.png);
    background-size: cover;
  }

  .instant-quote-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .instant-quote-text {
    max-width: 343px;
    margin: 16px 0 40px;
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
  }

  .instant-quote-container .main-btn {
    width: 100%;
    max-width: 314px;
    margin-right: 0;
  }
}
