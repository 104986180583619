.custom-input {
  position: relative;
  margin-bottom: 40px;
}

.label-error {
  color: var(--color-red) !important;
}

.custom-input > label {
  position: absolute;
  top: -8px;
  left: 16px;
  padding: 0 4px;
  color: var(--color-grey-1);
  background-color: #ffffff;
  font-size: 12px;
  line-height: 150%;
}

.custom-input > input {
  width: 320px;
  height: 40px;
  padding: 0 12px;
  border: 1px solid rgba(167, 173, 195, 0.3);
  border-radius: 8px;
  color: var(--color-black);
  font-family: var(--default-font);
  font-size: 16px;
  line-height: 150%;
}

.custom-input > textarea {
  width: 320px;
  height: 100px;
  padding: 4px 12px;
  border: 1px solid rgba(167, 173, 195, 0.3);
  border-radius: 8px;
  color: var(--color-black);
  font-family: var(--default-font);
  font-size: 16px;
  line-height: 150%;
}

.custom-input:last-child {
  margin-bottom: 60px;
}

.custom-input > input:focus-visible {
  outline: none;
  border-color: var(--color-grey-1);
}

.custom-input > textarea:focus-visible {
  outline: none;
  border-color: var(--color-grey-1);
}

.custom-input > .input-error:focus-visible,
.custom-input > .input-error {
  outline: none;
  border-color: var(--color-red);
}

.error-message {
  color: var(--color-red);
  font-size: 14px;
  line-height: 150%;
}

.custom-input > input::placeholder {
  color: var(--color-grey-2);
}

.custom-input > textarea::placeholder {
  color: var(--color-grey-2);
}

@media screen and (max-width: 480px) {
  .custom-input > label {
    position: static;  
    padding-left: 0;    
    margin-bottom: 5px; 
  }

  .custom-input > input {
    width: 100%;        
  }

  .custom-input > textarea {
    width: 100%;        
  }
}