.nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nav-element {
  margin-right: 56px;
  font-weight: 500;
  font-size: var(--size-text);
  line-height: 19px;
  color: var(--color-grey-1);
}

.our-services-nav {
  position: relative;
  padding-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.nav-dropdown {
  display: none;
  position: absolute;
  top: 28px;
  width: 203px;
  height: auto;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 0;
  border: 1px solid var(--color-grey-3);
  border-radius: 8px;
  background-color: #ffffff;
}

.nav-dropdown-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-dropdown-text-icon {
  margin-left: 2px;
  transition: transform 0.5s ease;
}

.nav-dropdown-text-icon.open {
  transform: rotate(180deg);
}

.nav-dropdown-text-icon.open {
  display: flex;
}

.nav-dropdown.visible {
  display: flex;
}

@media (max-width: 980px) {
  .nav-dropdown.visible {
    display: none;
  }
}
.our-services-nav:hover > .nav-dropdown.dark {
  background: #131c3c;
  color: var(--color-white);
  border: 1px solid #e9ebf2;
}

.secondary-nav-element {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 16px;
  font-size: 14px;
  line-height: 16px;
}

.secondary-nav-element.light {
  color: var(--color-black);
}

.secondary-nav-element.dark:hover {
  background-color: var(--color-secondary);
}

.secondary-nav-element.dark.active {
  background-color: var(--color-secondary);
}

.secondary-nav-element.light:hover {
  background-color: #fafafa;
}

.secondary-nav-element.light.active {
  color: var(--color-secondary);
}

.nav-element:last-child {
  margin-right: 0;
}

.nav-element.dark:hover,
.nav-element.dark.active {
  color: var(--color-grey-3);
}

.nav-element.light:hover,
.nav-element.light.active {
  color: var(--color-black);
}

.our-services-nav:hover > .nav-element.light {
  color: var(--color-black);
}

.our-services-nav:hover > .nav-element.dark {
  color: var(--color-grey-3);
}
