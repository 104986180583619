/* VideoBackground.css */
.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1; /* Ensure it stays in the background */
  }
  
  .video-background .video-tag {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    filter: grayscale(100%);
  }

  @media (max-width: 460px) {
  
    .video-background .video-tag {
      object-fit: cover; /* Ensures video covers the area without losing aspect ratio */
      width: 100%; /* Ensure full width */
      height: auto; /* Adjust height automatically */
    }
  }
  